<template>
  <router-view />
</template>
<script>
export default {
  name: 'ToTripwePay',
  created () {
    this.$store.commit('setBreadcrumbItems', [
        { text: 'Home', routeName: 'Home' },
        { text: 'TripwePay', routeName: 'TripwePay' },
        { text: 'Transfer', routeName: 'Transfer' },
        { text: 'Transfer TripwePay', routeName: 'ToTripwePay' }
    ]);
  },
}
</script>